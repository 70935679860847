const layoutData = {
  menu: {
    desktop: [
      { 
        link: '/minha-conta/novo-pedido',
        icon: 'payment',
        label: 'Novo Pedido',
        isDisabled: true,
      },
      {
        link: '/minha-conta/meus-pedidos',
        icon: 'requests',
        label: 'Meus Pedidos',
        isDisabled: true,
      },
      {
        link: '/minha-conta/open-finance',
        icon: 'finance',
        label: 'Open Finance',
        isDisabled: true,
      }
    ],
    mobile: [
      {
        link: '/minha-conta/novo-pedido',
        icon: 'payment',
        label: 'Novo Pedido',
        isDisabled: true,
      },
      {
        link: '/minha-conta/meus-pedidos',
        icon: 'requests',
        label: 'Meus Pedidos',
        isDisabled: true,
      },
      {
        link: '',
        icon: 'help',
        label: 'Ajuda',
        emit: 'help:show',
        isDisabled: false,
      },
      {
        link: '/minha-conta',
        icon: 'finance',
        label: 'Open Finance',
        isDisabled: true,
      }
    ],
    isActive: null
  },
  modal: {
    type: 1,
    title: {
      align: 'center',
      text: 'Desculpe, estamos com problemas.'
    },
    content: {
      align: 'center',
      text: '<p>Retorne em alguns instantes.</p>'
    },
    actions: [
      { label: 'Voltar', emit: 'modal:close', outline: false },
    ],
    isActive: null
  },
  showHelp: null,
  isLogged: true
}

const layoutKey = Symbol()

export { layoutData, layoutKey }